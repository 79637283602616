.content {
  @apply min-h-screen bg-blue-lighter pt-16;
}

.content__main {
  @apply relative text-white w-52 mx-auto;
}

small {
  @apply text-white text-center;
}

.content__main__img {
  position: relative;
  z-index: 10;
}

.content__main__img img {
  @apply w-32 mx-auto transition-all ease-in cursor-pointer;
}

.content__main__img img:active {
  @apply transform scale-95 brightness-200;
}

.content__main__img__speech-bubble {
  background: #ffdd1f;
  border-radius: 0.4em;
  width: 200px;
  min-height: 100px;
  height: auto;

  @apply text-black md:absolute md:top-0 md:right-0 md:-mr-64 md:p-3 block mt-4 p-2;
}

.content__main__links {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.content__main__img__speech-bubble:after {
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 43px solid transparent;
  border-right-color: #ffdd1f;
  border-left: 0;
  border-bottom: 0;
  margin-top: -21.5px;
  margin-left: -43px;
}

@screen sm {
  .content__main__img__speech-bubble:after {
    content: "";

    @apply block;
  }
}

.content__main span {
  @apply italic;
}
